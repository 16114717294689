import { Box, Input, Table, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import BucketItem from "../models/Item";
import { createItem, deleteItem, getItems, putItem } from "./BucketListService";
import Button from "./Button";

function createNewDate() {
	const date = new Date();
	return date.toLocaleDateString(undefined, {
		year: "numeric",
		month: "long",
		day: "numeric",
	});
}

function BucketList() {
	const [items, setItems] = useState<BucketItem[]>([]);
	const [newItem, setNewItem] = useState("");
	const [completedItems, setCompletedItems] = useState<number[]>([]);
	const [removedItems, setRemovedItems] = useState<number[]>([]);

	// load items from API on component mount
	useEffect(() => {
		// This effect runs once when the component mounts
		getItems()
			.then((items) => {
				setItems(items);
			})
			.catch((error) => {
				console.error("Error loading items:", error);
			});
	}, []);

	const handleAddItem = async () => {
		if (newItem.trim() !== "") {
			const newItemObject = {
				id: items.length,
				name: newItem,
				added: createNewDate(),
			};
			const updatedItems = [...items, newItemObject];
			setItems(updatedItems);
			setNewItem("");
			createItem(newItemObject);
		} else {
			alert("Please enter a valid item name");
		}
	};

	const handleToggleComplete = (index: number) => {
		const updatedItems = [...items];
		if (updatedItems[index].completed) {
			console.log("completedItems:", completedItems);
			updatedItems[index].completed = null;
			setCompletedItems(completedItems.filter((item) => item !== index));
			const updatedItem = {
				id: updatedItems[index].id,
				name: updatedItems[index].name,
				added: updatedItems[index].added,
				completed: null,
			};
			putItem(updatedItem.id, updatedItem);
			console.log("updatedItem:", updatedItem);
		} else {
			console.log("completedItems:", completedItems);
			updatedItems[index].completed = createNewDate();
			setCompletedItems([...completedItems, index]);
			const updatedItem = updatedItems[index];
			putItem(updatedItem.id, updatedItem);
			console.log("updatedItem:", updatedItem);
		}
		setItems(updatedItems);
	};

	const handleRemoveItem = (index: number) => {
		const updatedItems = [...items];
		const removedItem = updatedItems.splice(index, 1)[0];
		setCompletedItems(completedItems.filter((item) => item !== index));
		setItems(updatedItems);
		setRemovedItems([...removedItems, removedItem.id]);

		deleteItem(removedItem.id);
	};

	const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === "Enter") {
			handleAddItem();
		}
	};

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				gap: 1,
				p: 2,
			}}
		>
			<Typography level="h4">Bucket List</Typography>
			<Input
				type="text"
				value={newItem}
				onChange={(e) => setNewItem(e.target.value)}
				onKeyDown={handleKeyDown}
				placeholder="Add new item"
				sx={{ mb: 1 }}
			/>
			<Button onClick={handleAddItem} sx={{ mb: 2, mr: 2 }}>
				<Typography>Add</Typography>
			</Button>
			<Table>
				<thead>
					<tr>
						<th
							style={{
								textAlign: "center",
								justifyContent: "center",
							}}
						>
							Name
						</th>
						<th
							style={{
								textAlign: "center",
								justifyContent: "center",
							}}
						>
							Added On
						</th>
						<th
							style={{
								textAlign: "center",
								justifyContent: "center",
								overflowWrap: "break-word",
								whiteSpace: "normal",
								maxWidth: "100%",
							}}
						>
							Completed On
						</th>
						<th
							style={{
								textAlign: "center",
								justifyContent: "center",
								overflowWrap: "break-word",
								whiteSpace: "normal",
							}}
						></th>
					</tr>
				</thead>
				<tbody>
					{items.map((item, index) => (
						<tr key={index}>
							<td
								style={{
									textDecoration: item.completed
										? "line-through"
										: "none",
								}}
							>
								{item.name}
							</td>
							<td>{item.added}</td>
							<td>{item.completed ? item.completed : "-"}</td>
							<td style={{ textAlign: "left" }}>
								<Button
									onClick={() => handleToggleComplete(index)}
									sx={{ mb: 1, mr: 1 }}
								>
									<Typography sx={{ color: "black" }}>
										{item.completed
											? "Undo Complete"
											: "Complete"}
									</Typography>
								</Button>
								<Button
									onClick={() => handleRemoveItem(index)}
									sx={{ mb: 1 }}
								>
									<Typography sx={{ color: "black" }}>
										Remove
									</Typography>
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>
		</Box>
	);
}

export default BucketList;
