import { Stack } from "@mui/joy";

import React from "react";
import AnimatedGradientText from "./AnimatedGradientText";
import DrawerMobileNavigation from "./DrawerMobileNavigation";

const Header: React.FC = () => {
	return (
		<Stack direction="row" alignItems="center" spacing={2}>
			<DrawerMobileNavigation />
			<AnimatedGradientText>Jopshel's Adventure Hub</AnimatedGradientText>
		</Stack>
	);
};

export default Header;
