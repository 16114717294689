import { Button as MuiButton } from "@mui/joy";
import { SxProps, styled } from "@mui/system";
import React from "react";

const StyledButton = styled(MuiButton)({
	color: "#f35626",
	backgroundImage: "linear-gradient(92deg, #f35626, #feab3a)",
	textFillColor: "000000",
	padding: "10px 20px",
	minWidth: "100px",
});

interface ButtonProps {
	children: React.ReactNode;
	onClick: () => void;
	sx?: SxProps;
}

const Button: React.FC<ButtonProps> = ({ children, onClick, sx }) => {
	return (
		<StyledButton onClick={onClick} sx={sx}>
			{children}
		</StyledButton>
	);
};

export default Button;
