import {
	Button,
	FormControl,
	FormHelperText,
	Input,
	Typography,
} from "@mui/joy";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import "./index.css";
import AppRoutes from "./routes";
const PASSWORD = "adonis";

function App() {
	const [authenticated, setAuthenticated] = useState(false);
	const [input, setInput] = useState("");

	const handleInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		setInput(event.target.value);
	};

	// check if authenticated from local storage

	React.useEffect(() => {
		const isAuth = localStorage.getItem("authenticated");
		if (isAuth === "true") {
			setAuthenticated(true);
		}
	}, []);

	const handleSubmit = async (event: React.FormEvent) => {
		event.preventDefault();
		if (input.toLowerCase() === PASSWORD) {
			setAuthenticated(true);
			localStorage.setItem("authenticated", "true");
		} else {
			alert("Incorrect password");
		}
	};

	return (
		<Router>
			{authenticated ? (
				<>
					<Header />
					<AppRoutes />
					<Footer />
				</>
			) : (
				<div
					style={{
						background:
							"linear-gradient(to right, #ff758c, #ff7eb3)",
						height: "100vh",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						flexDirection: "column",
					}}
				>
					<form onSubmit={handleSubmit}>
						<FormControl>
							<Input
								placeholder="Password"
								onChange={handleInput}
								className="password-input"
							/>
							<FormHelperText>
								<Typography fontStyle="italic">
									What store gives us diabetes?
								</Typography>
							</FormHelperText>
						</FormControl>
						<Button type="submit">Submit</Button>
					</form>
				</div>
			)}
		</Router>
	);
}

ReactDOM.render(<App />, document.getElementById("root"));
