import { Route, Routes } from "react-router-dom";
import BucketList from "./components/BucketList";
import Home from "./components/Home";
import Scrapbook from "./components/Scrapbook";
import Timeline from "./components/Timeline";

const AppRoutes: React.FC = () => (
	<>
		<Routes>
			<Route path="/" element={<Home />} />
			<Route path="/bucket-list" element={<BucketList />} />
			<Route
				path="/scrapbook"
				element={<Scrapbook photos={[]} videos={[]} />}
			/>
			<Route path="/timeline" element={<Timeline events={[]} />} />
		</Routes>
	</>
);

export default AppRoutes;
