import React from "react";

const Home: React.FC = () => {
	return (
		<div style={{ textAlign: "center" }}>
			<h1>Merry Christmas !!!</h1>
			<h2>Happy New Year !!! </h2>
			<p>
				Happy New Years Josphel, I hope 2024 will be a year full of
				success and happiness.
			</p>
			<p>I appreciate all the memories and the time we spent together.</p>
			<p>I love you like a brother, thank you for being there.</p>
			<p>This gift late asf, but I hope you find it somewhat useful</p>
			<p>
				The goal was to make it have multiple adventuire related pieces,
				but I sadly ran out of time
			</p>
			<p>
				Your pookie, <br />- Felo
			</p>
			<img
				src={process.env.PUBLIC_URL + "/image0.png"}
				alt="description"
				style={{ maxWidth: "50%" , maxHeight: "50%"}}
			/>
		</div>
	);
};

export default Home;
