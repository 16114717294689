import React, { useState } from "react";

interface ScrapbookProps {
	photos: Array<string>;
	videos: Array<string>;
}

const Scrapbook: React.FC<ScrapbookProps> = ({ photos, videos }) => {
	const [selectedPhoto, setSelectedPhoto] = useState<string | null>(null);

	const handlePhotoClick = (photo: string) => {
		setSelectedPhoto(photo);
	};

	return (
		<div>
			<h2>Scrapbook</h2>
			<div>
				<h3>Photos</h3>
				<ul>
					{photos.map((photo, index) => (
						<li key={index} onClick={() => handlePhotoClick(photo)}>
							{photo}
						</li>
					))}
				</ul>
				{selectedPhoto && <img src={selectedPhoto} alt="Selected" />}
			</div>
			<div>
				<h3>Videos</h3>
				<ul>
					{videos.map((video, index) => (
						<li key={index}>{video}</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default Scrapbook;
