import { createClient } from "@supabase/supabase-js";
import BucketItem from "../models/Item";

// Create a single supabase client for interacting with your database
const supabase = createClient(
	"https://ihoywxbrucojqpzarkll.supabase.co",
	"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imlob3l3eGJydWNvanFwemFya2xsIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MTM5NzU0NjMsImV4cCI6MjAyOTU1MTQ2M30.zEyg1C-dIKYOj45zc1aH-fjt04TRkyHMNkXqCNQB1Yk"
);

export const getItems = async (): Promise<BucketItem[]> => {
	try {
		const { data, error } = await supabase.from("josphel").select();
		console.log(data);
		if (error) {
			console.error("Error retrieving items:", error);
			throw new Error(
				"Failed to retrieve items. Please check your network connection."
			);
		}
		return data;
	} catch (error) {
		console.error("Error retrieving items:", error);
		throw new Error(
			"Failed to retrieve items. Please check your network connection."
		);
	}
};

export const deleteItem = async (itemId: number): Promise<void> => {
	try {
		const { error } = await supabase
			.from("josphel")
			.delete()
			.eq("id", itemId);
		if (error) {
			console.error("Error deleting item:", error);
			throw new Error("Failed to delete item. Please try again.");
		}
		console.log(`Item deleted successfully: ${itemId}`);
	} catch (error) {
		console.error(`Error deleting item ${itemId}:`, error);
		throw new Error(`Failed to delete item ${itemId}. Please try again.`);
	}
};

export const createItem = async (newItem: BucketItem): Promise<BucketItem> => {
	try {
		console.log(JSON.stringify(newItem));
		const { error } = await supabase.from("josphel").insert({
			id: newItem.id,
			name: newItem.name,
			added: newItem.added,
			completed: newItem.completed,
		});
		if (error) {
			console.error("Error creating item:", error);
			throw new Error("Failed to create item. Please try again.");
		}
		console.log("Item created successfully");
		return newItem;
	} catch (error) {
		console.error("Error creating item:", error);
		throw new Error("Failed to create item. Please try again.");
	}
};

export const putItem = async (
	itemId: number,
	updatedItem: BucketItem
): Promise<void> => {
	try {
		const { error } = await supabase
			.from("josphel")
			.update({
				id: updatedItem.id,
				name: updatedItem.name,
				added: updatedItem.added,
				completed: updatedItem.completed,
			})
			.eq("id", itemId);
		if (error) {
			console.error("Error updating item:", error);
			throw new Error("Failed to update item. Please try again.");
		}
		console.log(`Item updated successfully: ${itemId}`);
	} catch (error) {
		console.error(`Error updating item ${itemId}:`, error);
		throw new Error(`Failed to update item ${itemId}. Please try again.`);
	}
};
