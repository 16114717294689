import styled, { keyframes } from "styled-components";

const hue = keyframes`
  from {
    -webkit-filter: hue-rotate(0deg);
  }
  to {
    -webkit-filter: hue-rotate(-360deg);
  }
`;
// font-size: 30px;
// 	line-height: 48px;
const AnimatedGradientText = styled.h1`
	color: #f35626;
	background-image: -webkit-linear-gradient(92deg, #f35626, #feab3a);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	-webkit-animation: ${hue} 10s infinite linear;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
		sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
	font-feature-settings: "kern";
	font-weight: 700;
	overflow-wrap: break-word;
	text-align: center;
	text-rendering: optimizelegibility;
	-moz-osx-font-smoothing: grayscale;
`;

export default AnimatedGradientText;
