import React from "react";

interface TimelineProps {
	events: Array<{ date: string; description: string }>;
}

const Timeline: React.FC<TimelineProps> = ({ events }) => {
	return (
		<div>
			<h2>Timeline</h2>
			<ul>
				{events.map((event, index) => (
					<li key={index}>
						<strong>{event.date}:</strong> {event.description}
					</li>
				))}
			</ul>
		</div>
	);
};

export default Timeline;
