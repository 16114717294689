import React from "react";

const Footer: React.FC = () => {
	return (
		<footer
			style={{ position: "fixed", bottom: 0, left: 16, width: "100%" }}
		>
			<p>Made with 💖 for Hoesphel</p>
		</footer>
	);
};

export default Footer;
	