import Menu from "@mui/icons-material/Menu";
import Box from "@mui/joy/Box";
import Drawer from "@mui/joy/Drawer";
import IconButton from "@mui/joy/IconButton";
import List from "@mui/joy/List";
import ListItemButton from "@mui/joy/ListItemButton";
import ModalClose from "@mui/joy/ModalClose";
import Typography from "@mui/joy/Typography";
import * as React from "react";
import { Link } from "react-router-dom";

export default function DrawerMobileNavigation() {
	const [open, setOpen] = React.useState(false);

	const handleCloseDrawer = () => {
		setOpen(false);
	};

	return (
		<React.Fragment>
			<IconButton
				color="neutral"
				onClick={() => setOpen(true)}
			>
				<Menu />
			</IconButton>
			<Drawer open={open} onClose={handleCloseDrawer}>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						gap: 0.5,
						ml: "auto",
						mt: 1,
						mr: 2,
					}}
				>
					<Typography
						component="label"
						htmlFor="close-icon"
						fontSize="sm"
						fontWeight="lg"
						sx={{ cursor: "pointer" }}
					>
						Close
					</Typography>
					<ModalClose id="close-icon" sx={{ position: "initial" }} />
				</Box>
				<List
					size="lg"
					component="nav"
					sx={{
						flex: "none",
						fontSize: "xl",
						"& > div": { justifyContent: "center" },
					}}
				>
					<ListItemButton
						component={Link}
						to="/"
						sx={{ fontWeight: "lg" }}
						onClick={handleCloseDrawer} // Close drawer when Home is clicked
					>
						Home
					</ListItemButton>
					<ListItemButton
						component={Link}
						to="/bucket-list"
						onClick={handleCloseDrawer} // Close drawer when Bucket List is clicked
					>
						Bucket List
					</ListItemButton>
				</List>
			</Drawer>
		</React.Fragment>
	);
}
